<template>
  <div class="vue-dynamique-class">
    <div class="box-setting-header index-style-user">
      <div class="title ml-4 mt-2">Gérer les vues</div>
      <div class="chargement-message-text" v-if="getLoadingVueDynamiqueAV">
        Chargement en cour
      </div>
      <div>
        <b-button variant="success" class="config-btn ml-2" v-b-modal.addvue>
          <font-awesome-icon icon="plus" /> Ajouter une vue</b-button
        >
        <b-modal
          id="addvue"
          ref="addvue"
          title="Ajouter une vue"
          :hide-footer="true"
          :hide-header="true"
          no-close-on-backdrop
          @hidden="resetModal"
          modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-frais"
        >
          <div class="hader mb-2">
            <div class="titleSetting">Ajouter une vue</div>
            <div class="iconClose" @click.prevent="hideModal('addvue')">
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="hendleSubmitVue"
                class="form-modal-custom-style"
              >
                <b-form-group
                  label="Nom de vue"
                  label-for="name"
                  class="input-modal-champ "
                >
                  <b-form-input
                    id="name"
                    v-model="vueToAdd.name"
                    required
                    class="mb-2"
                  ></b-form-input>
                </b-form-group>
                <b-form-checkbox
                  v-model="checkAll"
                  switch
                  @change="changeAllCheckColumns"
                  >Tous</b-form-checkbox
                >
                <b-form-group label="" v-slot="{ ariaDescribedby }">
                  <div class="custom-class-fields-av">
                    <b-form-checkbox
                      v-for="option in getListeFields"
                      v-model="vueToAdd.selected"
                      :key="option.label"
                      :value="option"
                      :aria-describedby="ariaDescribedby"
                      name="flavour-3a"
                    >
                      {{ option.label }}
                    </b-form-checkbox>
                  </div>
                </b-form-group>
                <div class="actionModel mt-3">
                  <div class="messageError">
                    <div v-if="error" class="error">
                      <ul v-if="Array.isArray(error)">
                        <li v-for="(e, index) in error" :key="index">
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ error }}</div>
                    </div>
                  </div>
                  <b-button type="submit" class="button-valide-style ">
                    <span>
                      Valider
                      <div v-if="loading" class="loading ml-2">
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </span>
                  </b-button>
                </div>
              </form>
            </template> </Card
        ></b-modal>
      </div>
    </div>
    <div class="vue-body">
      <div class="block-vues">
        <div class="title">Vue</div>
        <div class="body-vue-dyn-av">
          <b-list-group class="custom-list-group custom-list-group-vue">
            <b-list-group-item
              button
              v-for="vueDyn in getVueDynamiqueAV"
              :key="vueDyn.name"
              @click.prevent="handleClickVueDynamique(vueDyn)"
            >
              <div class="list-item">
                <div
                  class="icon"
                  v-if="
                    selectedVueDynamique &&
                      selectedVueDynamique.name == vueDyn.name
                  "
                >
                  <font-awesome-icon icon="check" />
                </div>
                <div>
                  {{ vueDyn.name }}
                </div>
              </div>
              <b-dropdown
                class="drop-down-list-item"
                size="lg"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                text="..."
              >
                <b-dropdown-item @click.stop="handleUpdateVueDynamique(vueDyn)">
                  <font-awesome-icon class="icon" icon="pencil-alt" />Renommer
                </b-dropdown-item>
                <b-dropdown-item @click.stop="handleDeleteVueDynamique(vueDyn)">
                  <font-awesome-icon
                    class="icon"
                    icon="trash-alt"
                  />Supprimer</b-dropdown-item
                >
                <b-dropdown-item
                  @click.stop="handleDeleteVueDynamique(vueDyn, true)"
                >
                  <span v-if="vueDyn.blocked == false"
                    ><font-awesome-icon class="icon" icon="eye" />Bloquer</span
                  >
                  <span v-else
                    ><font-awesome-icon
                      class="icon"
                      icon="eye-slash"
                    />Débloquer</span
                  >
                </b-dropdown-item>
              </b-dropdown>
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>
      <div class="block-vue-details">
        <div class="custom-list-column-vu" v-if="selectedVueDynamique">
          <b-form-group label="" v-slot="{ ariaDescribedby }">
            <div class="custom-class-fields-av-update">
              <b-form-checkbox
                switch
                v-for="option in getListeFields"
                v-model="selectedVueDynamique.columns"
                :key="option.label"
                :value="option"
                :aria-describedby="ariaDescribedby"
                @change="changeColumns"
              >
                {{ option.label }}
              </b-form-checkbox>
            </div>
          </b-form-group>
        </div>
      </div>
    </div>
    <!--  Delete VueDynamique Modal -->
    <b-modal
      ref="VueDynamiqueDeleteModal"
      id="VueDynamiqueDeleteModal"
      no-close-on-backdrop
      :hide-footer="true"
      :title="
        edit == true
          ? selectedVueDynamiqueToDelete.blocked == true
            ? 'Débloquer une vue'
            : 'Bloquer une Vue'
          : 'Supprimer une Vue'
      "
      @hidden="hideModal('VueDynamiqueDeleteModal')"
    >
      <p>
        Êtes-vous certain de vouloir
        {{
          edit == true
            ? selectedVueDynamiqueToDelete.blocked == true
              ? 'Débloquer'
              : 'bloquer'
            : 'supprimer'
        }}
        la Vue
        <strong class="name">
          {{
            selectedVueDynamiqueToDelete
              ? selectedVueDynamiqueToDelete.name
              : ''
          }}
        </strong>
        ?
      </p>
      <div class="form-modal-custom-style">
        <div class="messageError">
          <div v-if="getErrorVueDynamiqueAV" class="error">
            <ul v-if="Array.isArray(getErrorVueDynamiqueAV)">
              <li v-for="(e, index) in getErrorVueDynamiqueAV" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getErrorVueDynamiqueAV }}</span>
          </div>
        </div>
        <div class="actionModel">
          <b-button
            class="button-valide-style "
            @click.prevent="handleModalSupprimerValider"
          >
            Confirmer
            <div v-if="getLoadingVueDynamiqueAV" class="loading ml-2">
              <div class="spinner-border" role="status"></div>
            </div>
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- END Delete VueDynamique Modal -->
    <!-- Update Name VueDynamique Modal -->
    <b-modal
      id="UpdateNameVueDynamiqueModal"
      ref="UpdateNameVueDynamiqueModal"
      no-close-on-backdrop
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap"
    >
      <div class="hader mb-3">
        <div class="titleSetting">Renommer une vue</div>

        <div
          class="iconClose"
          @click.prevent="hideModal('UpdateNameVueDynamiqueModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="submitUpdateNameVueDynamique"
            class="form-modal-custom-style"
          >
            <b-form-group
              label="Nom"
              label-for="Nom"
              class="input-modal-champ"
              v-if="
                selectedVueDynamiqueToUpdate &&
                  selectedVueDynamiqueToUpdate.name
              "
            >
              <b-form-input
                id="Nom"
                v-model="selectedVueDynamiqueToUpdate.name"
                required
              ></b-form-input>
            </b-form-group>

            <div class="messageError mt-3">
              <div v-if="getErrorVueDynamiqueAV" class="error">
                {{ getErrorVueDynamiqueAV }}
              </div>
            </div>
            <div class="actionModel">
              <b-button type="submit" class="button-valide-style ">
                <span
                  >Enregistrer
                  <div v-if="getLoadingVueDynamiqueAV" class="loading ml-2">
                    <div class="spinner-border" role="status"></div></div
                ></span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <!-- END Update Name Modal-->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import fieldsAnalyseValidationModal from '../../models/fieldsAnalyseValidationModal';
export default {
  name: 'Gestion-des-vues',
  data() {
    return {
      vueToAdd: {
        name: null,
        selected: []
      },
      loading: false,
      error: null,
      selectedVueDynamique: null,
      selectedVueDynamiqueToDelete: null,
      selectedVueDynamiqueToUpdate: null,
      edit: false,
      checkAll: true
    };
  },
  methods: {
    ...mapActions([
      'AddVueDynamiqueAV',
      'getAllVueDynamiqueAV',
      'deleteVueDynamiqueAV',
      'blockVueDynamiqueAV',
      'updateNameVueDynamiqueAV'
    ]),
    changeAllCheckColumns() {
      this.vueToAdd.selected = this.checkAll ? this.getListeFields.slice() : [];
    },
    async changeColumns() {
      const response = await this.updateNameVueDynamiqueAV(
        this.selectedVueDynamique
      );
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    resetModal() {
      this.vueToAdd = {
        name: null,
        selected: fieldsAnalyseValidationModal.create().list
      };
      this.loading = false;
      this.error = null;
      if (
        this.selectedVueDynamique &&
        this.selectedVueDynamiqueToDelete &&
        this.selectedVueDynamique.id == this.selectedVueDynamiqueToDelete.id
      ) {
        this.selectedVueDynamique = null;
      }
      this.selectedVueDynamiqueToDelete = null;
      this.selectedVueDynamiqueToUpdate = null;
      this.checkAll = true;
    },
    async hendleSubmitVue() {
      this.loading = true;
      this.error = null;
      if (this.vueToAdd.selected.length == 0) {
        this.loading = false;
        this.error = ['column:required'];
        return;
      }
      const formData = new FormData();
      formData.append('name', this.vueToAdd.name);
      for (let i = 0; i < this.vueToAdd.selected.length; i++) {
        formData.append(
          'columns[' + i + '][key]',
          this.vueToAdd.selected[i].key
        );
        formData.append(
          'columns[' + i + '][label]',
          this.vueToAdd.selected[i].label
        );
        if (this.vueToAdd.selected[i].thClass) {
          formData.append(
            'columns[' + i + '][thClass]',
            this.vueToAdd.selected[i].thClass
          );
        } else {
          formData.append('columns[' + i + '][thClass]', '');
        }
        if (this.vueToAdd.selected[i].tdClass) {
          formData.append(
            'columns[' + i + '][tdClass]',
            this.vueToAdd.selected[i].tdClass
          );
        } else {
          formData.append('columns[' + i + '][tdClass]', '');
        }
      }
      const response = await this.AddVueDynamiqueAV(formData);
      if (response.success) {
        this.loading = false;
        this.hideModal('addvue');
      } else {
        this.loading = false;
        this.error = response.error;
      }
    },
    handleDeleteVueDynamique(vueDyn, edit) {
      if (edit) {
        this.edit = true;
      }
      this.selectedVueDynamiqueToDelete = vueDyn;
      this.$refs['VueDynamiqueDeleteModal'].show();
    },
    async handleModalSupprimerValider() {
      if (this.edit) {
        const response = await this.blockVueDynamiqueAV(
          this.selectedVueDynamiqueToDelete
        );
        if (response) {
          this.hideModal('VueDynamiqueDeleteModal');
          this.edit = false;
        }
      } else {
        const response = await this.deleteVueDynamiqueAV(
          this.selectedVueDynamiqueToDelete.id
        );
        if (response) {
          this.hideModal('VueDynamiqueDeleteModal');
        }
      }
    },
    handleUpdateVueDynamique(vueDyn) {
      this.selectedVueDynamiqueToUpdate = { ...vueDyn };
      this.$refs['UpdateNameVueDynamiqueModal'].show();
    },
    async submitUpdateNameVueDynamique() {
      const response = await this.updateNameVueDynamiqueAV(
        this.selectedVueDynamiqueToUpdate
      );
      if (response) {
        this.hideModal('UpdateNameVueDynamiqueModal');
      }
    },
    handleClickVueDynamique(item) {
      this.selectedVueDynamique = item;
    }
  },
  components: {
    Card: () => import('../component/card.vue')
  },
  computed: {
    ...mapGetters([
      'getVueDynamiqueAV',
      'getErrorVueDynamiqueAV',
      'getLoadingVueDynamiqueAV'
    ])
  },
  mounted() {
    this.getListeFields = fieldsAnalyseValidationModal.create().list;
    this.vueToAdd.selected = fieldsAnalyseValidationModal.create().list;
    this.getAllVueDynamiqueAV();
  }
};
</script>

<style scoped lang="scss">
.vue-dynamique-class {
  .vue-body {
    margin-top: 10px;
    display: flex;
    width: 100%;
    height: calc(100vh - 129px);
    background-color: #f5f5f5c7;
    padding: 10px;
    border-radius: 15px;
    .block-vues {
      width: 30%;
      padding: 5px 20px;
      background-color: #fff;
      border-radius: 15px;

      .title {
        margin-bottom: 10px;
        margin-top: 10px;
        text-align: center;
        color: #2a2a2a;
        font-family: 'Montserrat', sans-serif;
        font-size: 13px;
        font-weight: 600;
      }
      .body-vue-dyn-av {
        .custom-list-group-vue {
          overflow-y: auto;
          max-height: calc(100vh - 150px);
          height: calc(100vh - 150px);
          .list-group-vue .list-group-item {
          }
        }
      }
    }
    .block-vue-details {
      width: 70%;
      padding: 5px 20px;
      background-color: #fff;
      border-radius: 15px;
      margin-left: 20px;
      overflow-y: auto;

      .custom-list-column-vu {
      }
    }
    .block-vue-details::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    .block-vue-details::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f0eef8;
      border-radius: 10px;
    }
    .block-vue-details::-webkit-scrollbar-thumb {
      background: #8d8cb7;
      border-radius: 7px;
    }
  }
}
</style>
<style lang="scss">
.custom-class-fields-av,
.custom-class-fields-av-update {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  overflow-x: auto;
  text-align-last: left;
  max-height: calc(100% - 90px);
}
.custom-class-fields-av {
  .custom-control {
    width: 20%;
  }
}
.custom-class-fields-av-update {
  .custom-control {
    width: 33%;
  }
}
</style>
