<template>
  <div class="moniteur-facture">
    <div class="box-setting-header">
      <div class="title ml-4 mt-2 mb-2">Moniteur des factures</div>
      <div></div>
    </div>
    <div class="entete">
      <div class="box-all-filter row">
        <div class="box-label-champ col-3">
          <div class="label-box-style">
            <span class="title-tabel">N° facture</span>
          </div>
          <b-form-input
            class="b-form-select-new-style width-filter"
            v-model="numFacture"
            @change="handleFilter"
            style="width: 145px"
          ></b-form-input>
        </div>
        <div class="box-label-champ col-3">
          <div class="label-box-style w-103-px">
            <span class="title-tabel">Acheteur</span>
          </div>
          <multiselect
            v-model="filterAcheteur"
            :options="listeFiliales"
            :multiple="true"
            label="text"
            track-by="value"
            class="customSelectMultipleFilter"
            :showLabels="false"
            placeholder="sélectionner filiale"
            :loading="getChargementFactures"
            @input="handleFilter"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :preselect-first="true"
          >
            <template slot="selection" slot-scope="{ values, isOpen }"
              ><span
                :class="{
                  'multiselect__single showBlock': !isOpen,
                  'multiselect__single ': isOpen
                }"
                v-if="values.length"
                >{{ values.length }} options selected</span
              ></template
            >
          </multiselect>
        </div>
        <div class="box-label-champ col-3">
          <div class="label-box-style w-103-px">
            <span class="title-tabel">Vendeur</span>
          </div>
          <multiselect
            v-model="filterVendeur"
            :options="listeFiliales"
            :multiple="true"
            label="text"
            track-by="value"
            class="customSelectMultipleFilter"
            :showLabels="false"
            placeholder="sélectionner filiale"
            :loading="getLoadingDataFilterObligeeThProjects"
            @input="handleFilter"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :preselect-first="true"
          >
            <template slot="selection" slot-scope="{ values, isOpen }"
              ><span
                :class="{
                  'multiselect__single showBlock': !isOpen,
                  'multiselect__single ': isOpen
                }"
                v-if="values.length"
                >{{ values.length }} options selected</span
              ></template
            >
          </multiselect>
        </div>
        <div class="box-label-champ col-3">
          <div class="label-box-style w-103-px">
            <span class="title-tabel">Organsime</span>
          </div>
          <multiselect
            v-model="filterOrganisme"
            :options="getListOrganismeObligee"
            :multiple="true"
            label="name"
            track-by="id"
            class="customSelectMultipleFilter"
            :showLabels="false"
            placeholder="sélectionner organisme"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :loading="getChargementFactures"
            @input="handleFilter"
          >
            <template slot="selection" slot-scope="{ values, isOpen }"
              ><span
                :class="{
                  'multiselect__single showBlock': !isOpen,
                  'multiselect__single ': isOpen
                }"
                v-if="values.length"
                >{{ values.length }} options selected</span
              ></template
            >
          </multiselect>
        </div>
        <div class="box-label-champ col-3">
          <div class="label-box-style w-93-px">
            <span class="title-tabel">Date facturation</span>
          </div>
          <date-picker
            value-type="format"
            type="date"
            required
            :value="date_facturation"
            @input="handleDateTimePicker($event, 'date_facturation')"
            :disabled-date="disabledFacturationDate"
            input-class="custom-date-picker-filter"
            class="custom-date-picker-calender-filter"
          ></date-picker>
        </div>
        <div class="box-label-champ col-3">
          <div class="label-box-style w-93-px">
            <span class="title-tabel">Date paiement</span>
          </div>
          <date-picker
            value-type="format"
            type="date"
            required
            :value="date_paiement"
            @input="handleDateTimePicker($event, 'date_paiement')"
            :disabled-date="disabledPaiementDate"
            input-class="custom-date-picker-filter"
            class="custom-date-picker-calender-filter"
          ></date-picker>
        </div>
        <div class="box-label-champ col-3">
          <div class="label-box-style w-93-px">
            <span class="title-tabel">Date règlement</span>
          </div>
          <date-picker
            value-type="format"
            type="date"
            :value="date_reglement"
            @input="handleDateTimePicker($event, 'date_reglement')"
            :clear-button="true"
            :disabled-date="disabledReglementDate"
            input-class="custom-date-picker-filter"
            class="custom-date-picker-calender-filter"
          ></date-picker>
        </div>
        <div v-if="getChargementFactures" class="chargement">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="error-message ml-2">
          <div v-if="getErrorFactures" class="error">
            <ul v-if="Array.isArray(getErrorFactures)" class="mb-0">
              <li v-for="(e, index) in getErrorFactures" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getErrorFactures }}</div>
          </div>
        </div>
        <div class="box-end-position">
          <b-button
            size="sm"
            class="button-export-style ml-2"
            @click="refreshFactures"
          >
            <img src="@/assets/arrows_rotate.png"
          /></b-button>
        </div>
      </div>
    </div>
    <div class="mb-5">
      <b-table
        show-empty
        id="my-table"
        class="
          custom-table-style
          table-moniteur-facture table-header-regie
        "
        :items="getFilteredFactures"
        :fields="fields"
        bordered
        sticky-header
        hover
        responsive
        head-variant="light"
        :tbody-tr-class="rowClass"
        empty-text="Il n'y a aucun enregistrement à afficher"
      >
        <template v-slot:cell(vendeur)="data">
          {{ data.item.vendeur.name }}</template
        >
        <template v-slot:cell(acheteur)="data">
          {{ data.item.acheteur.name }}</template
        >
        <template v-slot:cell(responsable_acheteur)="data">
          {{ data.item.responsable_acheteur.name }}</template
        >
        <template v-slot:cell(locked)="data">
          <b-form-checkbox
            v-model="data.item.locked"
            switch
            @change="lockUnlockFacture(data.item)"
            v-b-tooltip.hover
            data-html="true"
            :title="
              data.item.locked_by != null || data.item.unlocked_by != null
                ? data.item.locked == true
                  ? 'verrouillé par: ' +
                    data.item.locked_by +
                    ' - Le: ' +
                    data.item.locked_at
                  : 'Déverrouillé par: ' +
                    data.item.unlocked_by +
                    ' - Le: ' +
                    data.item.unlocked_at
                : ''
            "
          ></b-form-checkbox>
        </template>
        <!-- <template slot="acheteur" slot-scope="data"
          > {{ data.item.acheteur.name }}
        </template> -->
      </b-table>
      <div v-if="this.getFilteredFactures" class="footer-style">
        <b-pagination
          v-model="page"
          :total-rows="getTotalRowsFactures"
          :per-page="per_page"
          aria-controls="my-table"
          pills
          align="center"
          size="sm"
          @change="pagination"
          class="pagination-style"
        ></b-pagination>
        <div class="per-page-element-style">
          <div class="box-label-champ">
            <div class="label-box-style">
              <span class="title-tabel">Eléments par page</span>
            </div>
          </div>
          <b-form-select
            v-model="per_page"
            :options="perPageList"
            @change="changePerPage"
            class="b-form-select-new-style bg-select"
          ></b-form-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'dashbord',

  data() {
    return {
      optionsSelectStatut: [{ full_name: 'a paye' }, { full_name: 'paye' }],
      date_facturation: null,
      date_paiement: null,
      date_reglement: null,
      page: 1,
      per_page: 10,
      filterFiliale: [],
      filterLot: [],
      filterOrganisme: [],
      filterAcheteur: [],
      filterVendeur: [],
      fields: [
        {
          key: 'numero_facture',
          label: 'Numéro de facture',
          for: 'numero_facture',
          views: true
        },
        {
          key: 'projet',
          label: 'Projet',
          for: 'projet',
          views: true
        },
        {
          key: 'date_facture',
          label: 'Date de facture',
          for: 'date_facture',
          views: true
        },
        {
          key: 'vendeur',
          label: 'Vendeur',
          for: 'vendeur',
          views: true
        },

        {
          key: 'acheteur',
          label: 'Acheteur',
          for: 'acheteur',
          views: true
        },
        {
          key: 'HT',
          label: 'HT',
          for: 'ht',
          views: true
        },
        {
          key: 'TVA',
          label: 'TVA',
          for: 'tva',
          views: true
        },
        {
          key: 'TTC',
          label: 'TTC',
          for: 'ttc',
          views: false
        },
        {
          key: 'acompte',
          label: 'Accompte',
          for: 'acompte',
          views: false
        },
        {
          key: 'reduction',
          label: 'Réduction',
          for: 'reduction',
          views: false
        },
        {
          key: 'responsable_acheteur',
          label: 'Responsable acheteur',
          for: 'responsable_acheteur',
          views: false
        },
        {
          key: 'date_paiement',
          label: 'Date de paiement',
          for: 'date_paiement',
          views: false
        },

        {
          key: 'date_reglement',
          label: 'Date de reglement',
          for: 'date_reglement',
          views: true
        },
        {
          key: 'locked',
          label: 'Verrouillé',
          for: 'locked',
          views: false
        }
      ],
      checkAll: false,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      numFacture: null
    };
  },
  methods: {
    ...mapActions([
      'getSettingFilialeTh',
      'getFacturesFiltred',
      'getAllFilialesListe',
      'updateLockFacture'
    ]),
    disabledFacturationDate(date) {
      return this.date_paiement && date >= new Date(this.date_paiement);
    },
    disabledPaiementDate(date) {
      return this.date_facturation && date < new Date(this.date_facturation);
    },
    disabledReglementDate(date) {
      return this.date_reglement && date < new Date(this.date_reglement);
    },
    rowClass(item, type) {
      return 'ligneNormale';
    },
    handleDateTimePicker(value, name) {
      this[name] = value;
      //   this.getFacturesFiltred({
      //     date_facturation: this.date_facturation,
      //     date_paiement: this.date_paiement,
      //     page: this.page,
      //     per_page: this.per_page,
      //     numero_facture: this.numFacture
      //   });
      //   this.filterLot = [];
      //   this.filterFiliale = [];
      //   this.filterOrganisme = [];
      this.handleFilter();
    },
    handleFilter() {
      this.getFacturesFiltred({
        date_facturation: this.date_facturation,
        date_paiement: this.date_paiement,
        date_reglement: this.date_reglement,
        acheteur: this.filterAcheteur,
        vendeur: this.filterVendeur,
        page: this.page,
        per_page: this.per_page,
        numero_facture: this.numFacture
      });
    },
    async refreshFactures() {
      let tab = [];
      this.getFilteredFactures.map(row =>
        row.lots.map(l => {
          if (l.projects.some(p => p.check == true)) {
            tab.push(l.name);
          }
        })
      );
      const response = await this.getFacturesFiltred({});
      if (response == true) {
        this.handleFilter();
      }
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
      this.checkAll = false;
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
      this.checkAll = false;
    },
    lockUnlockFacture(item) {
      this.updateLockFacture({ id: item.id, locked: item.locked });
    }
  },
  computed: {
    ...mapGetters([
      'getChargementFactures',
      'getErrorFactures',
      'getFilteredFactures',
      'getTotalFactures',
      'getTotalRowsFactures',
      'listeFiliales'
    ]),
    computedFields() {
      return this.fields.filter(item => item.views == true);
    }
  },
  components: {},
  async mounted() {
    this.date_facturation = moment()
      .clone()
      .startOf('year')
      .format('YYYY-MM-DD');
    await this.getAllFilialesListe();
    await this.getFacturesFiltred({
      date_facturation: this.date_facturation,
      date_paiement: this.date_paiement,
      date_reglement: this.date_reglement,
      acheteur: this.filterAcheteur,
      vendeur: this.filterVendeur,
      page: this.page,
      per_page: this.per_page,
      numero_facture: this.numFacture
    });
  }
};
</script>

<style scoped lang="scss">
.box-all-filter {
  height: 100px !important;
}
.label-box-style {
  text-align: center !important;
}
.moniteur-facture {
  margin: 0px;
  width: 100%;
  padding: 10px;
  display: block;
  height: calc(100vh - 60px);
  overflow: hidden;
  .entete {
    width: 100%;
    .chargement {
      font-size: 8px;
      margin-left: 5px;
      .spinner-border {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  .body-box-rapport {
    height: calc(100vh - 175px);
  }
}
.icon-plus {
  font-size: 13px;
  color: #8d8cb7;
  &:hover {
    color: #4d4bac;
  }
}
.border-botom {
  border-bottom: 1px solid #f6f5fb;
}
.table-moniteur-facture {
  max-height: calc(100vh - 230px) !important;
  height: calc(100vh - 230px) !important;
  margin-bottom: 0px;
  overflow-x: scroll;
}
</style>
<style lang="scss">
.moniteur-facture {
  .table-rapport-style {
    display: block;
    #chart {
      position: relative;
      // max-width: 40%;
      // width: 38%;
      width: 100%;
    }
  }
  .justify-content-sb {
    display: flex !important;
    line-height: 11px !important;
    padding: 0px 8px !important;
  }
  .button-export-style {
    padding-left: 1.5px;
    padding-right: 1.7px;
    img {
      width: 24px;
    }
  }
}

.table-moniteur-facture {
  td {
    width: 100px;
    min-width: 100px;
    line-height: 15px;
    span {
      cursor: pointer;
    }
  }
  td,
  th {
    font-size: 9px;
  }
  th {
    padding: 6px 2px;
    width: 100px;
    min-width: 100px;
  }
  .background-week {
    justify-content: start !important;
    background-color: #e0e0eb;
    color: #fff;
    td {
      width: 100px;
      min-width: 100px;
      padding: 0px;
      margin: 0px;
      font-size: 9.5px;
      // border-right: 1px solid white;
    }
    .button-succes-style {
      background-color: #6d6c99;
      padding: 6px 11px;
      margin: 2px 0px;
    }
    .icon-info-show {
      color: #e0e0eb !important;
    }
    .item-details-style {
      align-self: center;
    }
  }
  .background-depot {
    justify-content: start !important;
    background-color: #b0afd9;
    padding: 4px 0px;
    td {
      width: 100px;
      min-width: 100px;
      padding: 0px;
      margin: 0px;
      font-size: 9.5px;
    }
    .button-succes-style {
      background-color: #fff;
      color: #4d4bac;
      padding: 6px 11px;
      margin: 2px 0px;
    }
    .icon-info-show {
      color: #b0afd9 !important;
    }
    .item-details-style {
      align-self: center;
      color: #fff;
    }
  }
}
.custom-date-picker-calender-filter {
  width: 130px !important;
}
.info-icon-commercial-lot {
  font-size: 11.5px;
  margin-right: 4px;
  color: #e09f24;
  &:hover {
    color: #f5a206;
  }
}
// .icon-plus-double {
//   font-size: 13px;
//   margin-right: 4px;
//   color: #e09f24;
//   &:hover {
//     color: #f5a206;
//   }
// }
</style>
