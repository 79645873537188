var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vue-dynamique-class"},[_c('div',{staticClass:"box-setting-header index-style-user"},[_c('div',{staticClass:"title ml-4 mt-2"},[_vm._v("Gérer les vues")]),(_vm.getLoadingVueDynamiqueAV)?_c('div',{staticClass:"chargement-message-text"},[_vm._v(" Chargement en cour ")]):_vm._e(),_c('div',[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.addvue",modifiers:{"addvue":true}}],staticClass:"config-btn ml-2",attrs:{"variant":"success"}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}}),_vm._v(" Ajouter une vue")],1),_c('b-modal',{ref:"addvue",attrs:{"id":"addvue","title":"Ajouter une vue","hide-footer":true,"hide-header":true,"no-close-on-backdrop":"","modal-class":"cutsom-modal-bootstrap cutsom-modal-bootstrap-frais"},on:{"hidden":_vm.resetModal}},[_c('div',{staticClass:"hader mb-2"},[_c('div',{staticClass:"titleSetting"},[_vm._v("Ajouter une vue")]),_c('div',{staticClass:"iconClose",on:{"click":function($event){$event.preventDefault();return _vm.hideModal('addvue')}}},[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1)]),_c('Card',{scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('form',{staticClass:"form-modal-custom-style",on:{"submit":function($event){$event.preventDefault();return _vm.hendleSubmitVue.apply(null, arguments)}}},[_c('b-form-group',{staticClass:"input-modal-champ ",attrs:{"label":"Nom de vue","label-for":"name"}},[_c('b-form-input',{staticClass:"mb-2",attrs:{"id":"name","required":""},model:{value:(_vm.vueToAdd.name),callback:function ($$v) {_vm.$set(_vm.vueToAdd, "name", $$v)},expression:"vueToAdd.name"}})],1),_c('b-form-checkbox',{attrs:{"switch":""},on:{"change":_vm.changeAllCheckColumns},model:{value:(_vm.checkAll),callback:function ($$v) {_vm.checkAll=$$v},expression:"checkAll"}},[_vm._v("Tous")]),_c('b-form-group',{attrs:{"label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('div',{staticClass:"custom-class-fields-av"},_vm._l((_vm.getListeFields),function(option){return _c('b-form-checkbox',{key:option.label,attrs:{"value":option,"aria-describedby":ariaDescribedby,"name":"flavour-3a"},model:{value:(_vm.vueToAdd.selected),callback:function ($$v) {_vm.$set(_vm.vueToAdd, "selected", $$v)},expression:"vueToAdd.selected"}},[_vm._v(" "+_vm._s(option.label)+" ")])}),1)]}}])}),_c('div',{staticClass:"actionModel mt-3"},[_c('div',{staticClass:"messageError"},[(_vm.error)?_c('div',{staticClass:"error"},[(Array.isArray(_vm.error))?_c('ul',_vm._l((_vm.error),function(e,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(e)+" ")])}),0):_c('div',[_vm._v(_vm._s(_vm.error))])]):_vm._e()]),_c('b-button',{staticClass:"button-valide-style ",attrs:{"type":"submit"}},[_c('span',[_vm._v(" Valider "),(_vm.loading)?_c('div',{staticClass:"loading ml-2"},[_c('div',{staticClass:"spinner-border",attrs:{"role":"status"}})]):_vm._e()])])],1)],1)]},proxy:true}])})],1)],1)]),_c('div',{staticClass:"vue-body"},[_c('div',{staticClass:"block-vues"},[_c('div',{staticClass:"title"},[_vm._v("Vue")]),_c('div',{staticClass:"body-vue-dyn-av"},[_c('b-list-group',{staticClass:"custom-list-group custom-list-group-vue"},_vm._l((_vm.getVueDynamiqueAV),function(vueDyn){return _c('b-list-group-item',{key:vueDyn.name,attrs:{"button":""},on:{"click":function($event){$event.preventDefault();return _vm.handleClickVueDynamique(vueDyn)}}},[_c('div',{staticClass:"list-item"},[(
                  _vm.selectedVueDynamique &&
                    _vm.selectedVueDynamique.name == vueDyn.name
                )?_c('div',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":"check"}})],1):_vm._e(),_c('div',[_vm._v(" "+_vm._s(vueDyn.name)+" ")])]),_c('b-dropdown',{staticClass:"drop-down-list-item",attrs:{"size":"lg","variant":"link","toggle-class":"text-decoration-none","no-caret":"","text":"..."}},[_c('b-dropdown-item',{on:{"click":function($event){$event.stopPropagation();return _vm.handleUpdateVueDynamique(vueDyn)}}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"pencil-alt"}}),_vm._v("Renommer ")],1),_c('b-dropdown-item',{on:{"click":function($event){$event.stopPropagation();return _vm.handleDeleteVueDynamique(vueDyn)}}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"trash-alt"}}),_vm._v("Supprimer")],1),_c('b-dropdown-item',{on:{"click":function($event){$event.stopPropagation();return _vm.handleDeleteVueDynamique(vueDyn, true)}}},[(vueDyn.blocked == false)?_c('span',[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"eye"}}),_vm._v("Bloquer")],1):_c('span',[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"eye-slash"}}),_vm._v("Débloquer")],1)])],1)],1)}),1)],1)]),_c('div',{staticClass:"block-vue-details"},[(_vm.selectedVueDynamique)?_c('div',{staticClass:"custom-list-column-vu"},[_c('b-form-group',{attrs:{"label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var ariaDescribedby = ref.ariaDescribedby;
return [_c('div',{staticClass:"custom-class-fields-av-update"},_vm._l((_vm.getListeFields),function(option){return _c('b-form-checkbox',{key:option.label,attrs:{"switch":"","value":option,"aria-describedby":ariaDescribedby},on:{"change":_vm.changeColumns},model:{value:(_vm.selectedVueDynamique.columns),callback:function ($$v) {_vm.$set(_vm.selectedVueDynamique, "columns", $$v)},expression:"selectedVueDynamique.columns"}},[_vm._v(" "+_vm._s(option.label)+" ")])}),1)]}}],null,false,772730431)})],1):_vm._e()])]),_c('b-modal',{ref:"VueDynamiqueDeleteModal",attrs:{"id":"VueDynamiqueDeleteModal","no-close-on-backdrop":"","hide-footer":true,"title":_vm.edit == true
        ? _vm.selectedVueDynamiqueToDelete.blocked == true
          ? 'Débloquer une vue'
          : 'Bloquer une Vue'
        : 'Supprimer une Vue'},on:{"hidden":function($event){return _vm.hideModal('VueDynamiqueDeleteModal')}}},[_c('p',[_vm._v(" Êtes-vous certain de vouloir "+_vm._s(_vm.edit == true ? _vm.selectedVueDynamiqueToDelete.blocked == true ? 'Débloquer' : 'bloquer' : 'supprimer')+" la Vue "),_c('strong',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.selectedVueDynamiqueToDelete ? _vm.selectedVueDynamiqueToDelete.name : '')+" ")]),_vm._v(" ? ")]),_c('div',{staticClass:"form-modal-custom-style"},[_c('div',{staticClass:"messageError"},[(_vm.getErrorVueDynamiqueAV)?_c('div',{staticClass:"error"},[(Array.isArray(_vm.getErrorVueDynamiqueAV))?_c('ul',_vm._l((_vm.getErrorVueDynamiqueAV),function(e,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(e)+" ")])}),0):_c('span',[_vm._v(_vm._s(_vm.getErrorVueDynamiqueAV))])]):_vm._e()]),_c('div',{staticClass:"actionModel"},[_c('b-button',{staticClass:"button-valide-style ",on:{"click":function($event){$event.preventDefault();return _vm.handleModalSupprimerValider.apply(null, arguments)}}},[_vm._v(" Confirmer "),(_vm.getLoadingVueDynamiqueAV)?_c('div',{staticClass:"loading ml-2"},[_c('div',{staticClass:"spinner-border",attrs:{"role":"status"}})]):_vm._e()])],1)])]),_c('b-modal',{ref:"UpdateNameVueDynamiqueModal",attrs:{"id":"UpdateNameVueDynamiqueModal","no-close-on-backdrop":"","hide-footer":true,"hide-header":true,"modal-class":"cutsom-modal-bootstrap"},on:{"hidden":_vm.resetModal}},[_c('div',{staticClass:"hader mb-3"},[_c('div',{staticClass:"titleSetting"},[_vm._v("Renommer une vue")]),_c('div',{staticClass:"iconClose",on:{"click":function($event){$event.preventDefault();return _vm.hideModal('UpdateNameVueDynamiqueModal')}}},[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1)]),_c('Card',{scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('form',{staticClass:"form-modal-custom-style",on:{"submit":function($event){$event.preventDefault();return _vm.submitUpdateNameVueDynamique.apply(null, arguments)}}},[(
              _vm.selectedVueDynamiqueToUpdate &&
                _vm.selectedVueDynamiqueToUpdate.name
            )?_c('b-form-group',{staticClass:"input-modal-champ",attrs:{"label":"Nom","label-for":"Nom"}},[_c('b-form-input',{attrs:{"id":"Nom","required":""},model:{value:(_vm.selectedVueDynamiqueToUpdate.name),callback:function ($$v) {_vm.$set(_vm.selectedVueDynamiqueToUpdate, "name", $$v)},expression:"selectedVueDynamiqueToUpdate.name"}})],1):_vm._e(),_c('div',{staticClass:"messageError mt-3"},[(_vm.getErrorVueDynamiqueAV)?_c('div',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.getErrorVueDynamiqueAV)+" ")]):_vm._e()]),_c('div',{staticClass:"actionModel"},[_c('b-button',{staticClass:"button-valide-style ",attrs:{"type":"submit"}},[_c('span',[_vm._v("Enregistrer "),(_vm.getLoadingVueDynamiqueAV)?_c('div',{staticClass:"loading ml-2"},[_c('div',{staticClass:"spinner-border",attrs:{"role":"status"}})]):_vm._e()])])],1)],1)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }